@import 'nib'
@import '../variables'
@import '../sprite-site'

section
    &.noticia-detalhe
        .triple-list
            margin-top 45px
        .date
            font-weight $font-lato-bold
            text-transform uppercase
            margin-bottom 10px
            color $color-grey-dark2
        .picture
            position relative
            display block
            margin 36px auto 36px 0
            .legend
                position absolute
                width 100%
                left 0
                bottom 0
                padding 18px
                background-image url(../img/black-70-alpha.png)
                color $color-white
                font-size 13px
                line-height 16px
                display none
                &.small
                    position relative
                    bottom initial
        .lists
            margin-top 50px
            .col-1
                width 470px
                .title
                    &:before
                        sprite($links-icon)
            .col-2
                width 460px
                .title
                    &:before
                        sprite($related-files-icon)