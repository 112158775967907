@import 'nib'
@import '../variables'
@import '../sprite-site'

section
    &.produto-detalhe
        .sub-title
            font-size 14px
            font-weight $font-lato-bold
            text-transform uppercase
            margin-bottom 10px
            color $color-grey-dark2
        .container
            >.title
                &:after
                    content none
        .gallery
            margin-top 40px
            display block
            .windows
                position relative
                display block
                margin 0 auto
                width 600px
                height 600px
            .window
                position absolute
                display none
                top 0
                left 0
                width 100%
                height 100%
                &.selected
                    display block
                .contents
                    position absolute
                    top 0
                    left 0
                    border-color $color-grey-light7
                    border-width 1px 0 1px 1px
                    border-style solid
                    display block
                    width 479px
                    height 100%
                    float left
                    z-index 10
                    .content
                        position absolute
                        top 0
                        left 0
                        width 100%
                        height 100%
                        display none
                        >.name
                            position absolute
                            left 10px
                            bottom 8px
                            @media (min-width:501px)
                                display none
                        .nav_bar
                            display none
                        .preloader
                            position absolute
                            width 100%
                            top 50%
                            text-align center
                            margin-top -23px !important
                            span
                                font-weight $font-lato-bold
                                font-size 18px
                                color $color-red
                        .images
                            li
                                position absolute
                                top 0
                                left 0
                                img
                                    position relative
                                    top 0
                                    left 0
                                    width 100%
                                    height auto
                                    display none
                                    &.current-image
                                        display block
                        &.selected
                            display block
                .menu
                    position absolute
                    top 0
                    right 0
                    float left
                    width 131px
                    height 100%
                    border-color $color-grey-light7
                    border-width 1px 1px 1px 0
                    border-style solid
                    z-index 5
                    .item
                        position relative
                        display block
                        float left
                        width 130px
                        .crop
                            position absolute
                            top 0
                            left 0
                            width 10px
                            height 120px
                            background-color $color-white
                            z-index 6
                        a
                            display block
                            width 100%
                            height 120px
                            img
                                width 100%
                                height 100%
                        .name
                            background-image url(../img/black-30-alpha.png)
                            position absolute
                            bottom 0
                            left 0
                            width 100%
                            box-sizing border-box
                            padding 6px 10px 6px 20px
                            color $color-white
                            font-size 14px
                            font-weight $font-lato-bold
                            text-align center
                            text-transform uppercase
                            display none
                        &:hover,
                        &.selected
                            .crop
                                background-color transparent
                                background-image url(../img/product-menu-item-crop.png)
                                background-position 0 0
                                width 10px
                                height 100%
                            .name
                                display block
                                @media (max-width:500px)
                                    display none
            .types
                .items
                    margin 15px -10px 0
                    text-align center
                .item
                    margin 5px 10px
                    display inline-block
                    .btn
                        padding-left 30px
                        padding-right 30px
                    @media (max-width:530px)
                        display block
                        margin-left 10px
                        margin-right 10px
                        .btn
                            width 100%
        .lists
            margin-top 50px
            .col-1
                width 470px
                .title
                    &:before
                        sprite($links-icon)
            .col-2
                width 460px
                .title
                    &:before
                        sprite($related-files-icon)
        .triple-list
            margin-top 85px
        .send-email
            position fixed
            left 0
            top 0
            width 100%
            height 100%
            background-image url(../img/black-60-alpha.png)
            z-index 1000
            display none
            .content
                position absolute
                left 50%
                top 50%
                width 794px
                height 203px
                box-sizing border-box
                padding 55px
                margin-left (@width / -2)px
                margin-top (@height / -2)px
                background-color $color-white
                &.big
                    height 243px
                    // margin-top (@height / -2)px
            .title
                color $color-red
                font-size 34px
                font-weight $font-lato-bold
                text-transform uppercase
                padding-right 60px
            .close
                position absolute
                sprite($error-icon)
                top 55 + ((37/2) - (@height/2))px
                right 55px
                opacity 1
            .field
                float left
                width 460px
                @media (max-width:794px)
                    width 70%
                @media (max-width:500px)
                    width 100%
            .error-messages
                width 100%
                .text
                    width auto
            .submit
                float right
                button
                    padding-left 60px
                    padding-right 60px
                @media (max-width:794px)
                    width 30%
                    padding-left 10px
                    button
                        padding-left initial
                        padding-right initial
                        width 100%
                @media (max-width:500px)
                    width 100%
                    padding-left 0
                    padding-top 10px
            @media (max-width:794px)
                .content
                    width 100%
                    height auto
                    top 0
                    left 0
                    margin 0
                    padding 20px
                    &.big
                        height initial
                .close
                    spriteHeight($error-icon)
                    top 20 + ((37/2) - (@height/2))px
                    right 20px