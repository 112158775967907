@import 'nib'
@import '../variables'
@import '../sprite-site'
@import '../utils'

section
    &.produtos
        .search-form
            margin-top 25px
            @media (max-width:959px)
                padding 0 10px
            .items
                margin -10px
            .item
                margin 10px
                float left
                .btn-right
                    position absolute
                    border-color $color-grey-dark4
                    border-width 1px 1px 1px 0
                    border-style solid
                    top -1px
                    right -1px
                    width 40px
                    height 40px
                    background-color $color-grey-dark3
                    z-index 1
            .field-keyword
                width 395px
                // input
                //     padding 0 50px 0 10px
                //     line-height 16px
                //     placeholder-color($color-grey-dark2, 1px)
                .btn-right
                    &:after
                        content ''
                        position absolute
                        top 50%
                        left 50%
                        sprite($search-icon)
                        margin-top (@height / -2)px
                        margin-left (@width / -2)px
                @media (max-width:959px)
                    float none
                    display block
                    width 100%
                    margin 10px 0
            .field-category
                width 400px
                padding 0 50px 0 10px
                .btn-right
                    &:after
                        content ''
                        position absolute
                        top 50%
                        left 50%
                        sprite($dropdown-icon)
                        margin-top (@height / -2)px
                        margin-left (@width / -2)px
                label
                    line-height 38px
                    font-weight $font-lato-light
                @media (max-width:959px)
                    float none
                    display block
                    width 100%
                    margin 10px 0
            .submit
                width 105px
                button
                    width 100%
                @media (max-width:959px)
                    float none
                    display block
                    width 300px
                    margin 10px auto
                @media (max-width:340px)
                    width 100%
        .triple-list
            margin-top 40px