$aluplast-logo-menu_x = 0px;
$aluplast-logo-menu_y = 107px;
$aluplast-logo-menu_offset_x = 0px;
$aluplast-logo-menu_offset_y = -107px;
$aluplast-logo-menu_width = 97px;
$aluplast-logo-menu_height = 55px;
$aluplast-logo-menu_total_width = 252px;
$aluplast-logo-menu_total_height = 216px;
$aluplast-logo-menu_image = '../img/sprite-site.png';
$aluplast-logo-menu = 0px 107px 0px -107px 97px 55px 252px 216px '../img/sprite-site.png';
$archi-logo-small_x = 0px;
$archi-logo-small_y = 0px;
$archi-logo-small_offset_x = 0px;
$archi-logo-small_offset_y = 0px;
$archi-logo-small_width = 183px;
$archi-logo-small_height = 44px;
$archi-logo-small_total_width = 252px;
$archi-logo-small_total_height = 216px;
$archi-logo-small_image = '../img/sprite-site.png';
$archi-logo-small = 0px 0px 0px 0px 183px 44px 252px 216px '../img/sprite-site.png';
$archi-logo_x = 0px;
$archi-logo_y = 54px;
$archi-logo_offset_x = 0px;
$archi-logo_offset_y = -54px;
$archi-logo_width = 183px;
$archi-logo_height = 43px;
$archi-logo_total_width = 252px;
$archi-logo_total_height = 216px;
$archi-logo_image = '../img/sprite-site.png';
$archi-logo = 0px 54px 0px -54px 183px 43px 252px 216px '../img/sprite-site.png';
$bread-crumb-separator_x = 234px;
$bread-crumb-separator_y = 22px;
$bread-crumb-separator_offset_x = -234px;
$bread-crumb-separator_offset_y = -22px;
$bread-crumb-separator_width = 5px;
$bread-crumb-separator_height = 9px;
$bread-crumb-separator_total_width = 252px;
$bread-crumb-separator_total_height = 216px;
$bread-crumb-separator_image = '../img/sprite-site.png';
$bread-crumb-separator = 234px 22px -234px -22px 5px 9px 252px 216px '../img/sprite-site.png';
$download-icon_x = 128px;
$download-icon_y = 192px;
$download-icon_offset_x = -128px;
$download-icon_offset_y = -192px;
$download-icon_width = 16px;
$download-icon_height = 15px;
$download-icon_total_width = 252px;
$download-icon_total_height = 216px;
$download-icon_image = '../img/sprite-site.png';
$download-icon = 128px 192px -128px -192px 16px 15px 252px 216px '../img/sprite-site.png';
$dropdown-icon_x = 234px;
$dropdown-icon_y = 0px;
$dropdown-icon_offset_x = -234px;
$dropdown-icon_offset_y = 0px;
$dropdown-icon_width = 18px;
$dropdown-icon_height = 12px;
$dropdown-icon_total_width = 252px;
$dropdown-icon_total_height = 216px;
$dropdown-icon_image = '../img/sprite-site.png';
$dropdown-icon = 234px 0px -234px 0px 18px 12px 252px 216px '../img/sprite-site.png';
$error-icon_x = 34px;
$error-icon_y = 192px;
$error-icon_offset_x = -34px;
$error-icon_offset_y = -192px;
$error-icon_width = 24px;
$error-icon_height = 24px;
$error-icon_total_width = 252px;
$error-icon_total_height = 216px;
$error-icon_image = '../img/sprite-site.png';
$error-icon = 34px 192px -34px -192px 24px 24px 252px 216px '../img/sprite-site.png';
$link-icon_x = 102px;
$link-icon_y = 192px;
$link-icon_offset_x = -102px;
$link-icon_offset_y = -192px;
$link-icon_width = 16px;
$link-icon_height = 16px;
$link-icon_total_width = 252px;
$link-icon_total_height = 216px;
$link-icon_image = '../img/sprite-site.png';
$link-icon = 102px 192px -102px -192px 16px 16px 252px 216px '../img/sprite-site.png';
$links-icon_x = 142px;
$links-icon_y = 107px;
$links-icon_offset_x = -142px;
$links-icon_offset_y = -107px;
$links-icon_width = 30px;
$links-icon_height = 30px;
$links-icon_total_width = 252px;
$links-icon_total_height = 216px;
$links-icon_image = '../img/sprite-site.png';
$links-icon = 142px 107px -142px -107px 30px 30px 252px 216px '../img/sprite-site.png';
$maqina-logo-menu_x = 0px;
$maqina-logo-menu_y = 172px;
$maqina-logo-menu_offset_x = 0px;
$maqina-logo-menu_offset_y = -172px;
$maqina-logo-menu_width = 64px;
$maqina-logo-menu_height = 10px;
$maqina-logo-menu_total_width = 252px;
$maqina-logo-menu_total_height = 216px;
$maqina-logo-menu_image = '../img/sprite-site.png';
$maqina-logo-menu = 0px 172px 0px -172px 64px 10px 252px 216px '../img/sprite-site.png';
$quality-icon_x = 193px;
$quality-icon_y = 39px;
$quality-icon_offset_x = -193px;
$quality-icon_offset_y = -39px;
$quality-icon_width = 22px;
$quality-icon_height = 37px;
$quality-icon_total_width = 252px;
$quality-icon_total_height = 216px;
$quality-icon_image = '../img/sprite-site.png';
$quality-icon = 193px 39px -193px -39px 22px 37px 252px 216px '../img/sprite-site.png';
$related-files-icon_x = 193px;
$related-files-icon_y = 0px;
$related-files-icon_offset_x = -193px;
$related-files-icon_offset_y = 0px;
$related-files-icon_width = 31px;
$related-files-icon_height = 29px;
$related-files-icon_total_width = 252px;
$related-files-icon_total_height = 216px;
$related-files-icon_image = '../img/sprite-site.png';
$related-files-icon = 193px 0px -193px 0px 31px 29px 252px 216px '../img/sprite-site.png';
$responsabilidade-social-icon_x = 107px;
$responsabilidade-social-icon_y = 107px;
$responsabilidade-social-icon_offset_x = -107px;
$responsabilidade-social-icon_offset_y = -107px;
$responsabilidade-social-icon_width = 25px;
$responsabilidade-social-icon_height = 39px;
$responsabilidade-social-icon_total_width = 252px;
$responsabilidade-social-icon_total_height = 216px;
$responsabilidade-social-icon_image = '../img/sprite-site.png';
$responsabilidade-social-icon = 107px 107px -107px -107px 25px 39px 252px 216px '../img/sprite-site.png';
$search-icon_x = 193px;
$search-icon_y = 120px;
$search-icon_offset_x = -193px;
$search-icon_offset_y = -120px;
$search-icon_width = 24px;
$search-icon_height = 24px;
$search-icon_total_width = 252px;
$search-icon_total_height = 216px;
$search-icon_image = '../img/sprite-site.png';
$search-icon = 193px 120px -193px -120px 24px 24px 252px 216px '../img/sprite-site.png';
$send-email-icon_x = 154px;
$send-email-icon_y = 192px;
$send-email-icon_offset_x = -154px;
$send-email-icon_offset_y = -192px;
$send-email-icon_width = 18px;
$send-email-icon_height = 12px;
$send-email-icon_total_width = 252px;
$send-email-icon_total_height = 216px;
$send-email-icon_image = '../img/sprite-site.png';
$send-email-icon = 154px 192px -154px -192px 18px 12px 252px 216px '../img/sprite-site.png';
$send-email-white-icon_x = 182px;
$send-email-white-icon_y = 192px;
$send-email-white-icon_offset_x = -182px;
$send-email-white-icon_offset_y = -192px;
$send-email-white-icon_width = 18px;
$send-email-white-icon_height = 12px;
$send-email-white-icon_total_width = 252px;
$send-email-white-icon_total_height = 216px;
$send-email-white-icon_image = '../img/sprite-site.png';
$send-email-white-icon = 182px 192px -182px -192px 18px 12px 252px 216px '../img/sprite-site.png';
$success-icon_x = 193px;
$success-icon_y = 86px;
$success-icon_offset_x = -193px;
$success-icon_offset_y = -86px;
$success-icon_width = 31px;
$success-icon_height = 24px;
$success-icon_total_width = 252px;
$success-icon_total_height = 216px;
$success-icon_image = '../img/sprite-site.png';
$success-icon = 193px 86px -193px -86px 31px 24px 252px 216px '../img/sprite-site.png';
$toogle-menu-close_x = 0px;
$toogle-menu-close_y = 192px;
$toogle-menu-close_offset_x = 0px;
$toogle-menu-close_offset_y = -192px;
$toogle-menu-close_width = 24px;
$toogle-menu-close_height = 24px;
$toogle-menu-close_total_width = 252px;
$toogle-menu-close_total_height = 216px;
$toogle-menu-close_image = '../img/sprite-site.png';
$toogle-menu-close = 0px 192px 0px -192px 24px 24px 252px 216px '../img/sprite-site.png';
$toogle-menu-open_x = 68px;
$toogle-menu-open_y = 192px;
$toogle-menu-open_offset_x = -68px;
$toogle-menu-open_offset_y = -192px;
$toogle-menu-open_width = 24px;
$toogle-menu-open_height = 21px;
$toogle-menu-open_total_width = 252px;
$toogle-menu-open_total_height = 216px;
$toogle-menu-open_image = '../img/sprite-site.png';
$toogle-menu-open = 68px 192px -68px -192px 24px 21px 252px 216px '../img/sprite-site.png';

spriteWidth($sprite) {
  width: $sprite[4];
}

spriteHeight($sprite) {
  height: $sprite[5];
}

spritePosition($sprite) {
  background-position: $sprite[2] $sprite[3];
}

spriteImage($sprite) {
  background-image: url($sprite[8]);
}

sprite($sprite) {
  spriteImage($sprite)
  spritePosition($sprite)
  spriteWidth($sprite)
  spriteHeight($sprite)
  background-repeat no-repeat
}

spriteMarginAlign($sprite) {
  margin-top: (-($sprite[5])/2)px
  margin-left: (-($sprite[4])/2)px
} 
