@import 'nib'
@import '../variables'
@import '../sprite-site'

section
    &.fale-conosco
        .phone
            display block
            color $color-red
            font-size 26px
            font-weight $font-lato-regular
            line-height 28px
            .ddd
                font-size 18px
        .email
            display block
            margin-top 5px
            color $color-red
            font-size 20px
            font-weight $font-lato-regular
            line-height 24px
        .address
            display block
            margin-top 10px
            color $color-red
            font-size 16px
            font-weight $font-lato-regular
            line-height 20px
        .sides
            margin 45px -10px -10px -10px
            @media (max-width:959px)
                width 100%
                margin-left 0
                margin-right 0
            form
                margin-top 23px
            .side
                margin 0 10px 10px 10px
                float left
                width 460px
                @media (max-width:959px)
                    width 100%
                    margin-left 0
                    margin-right 0
                    margin-bottom 40px
            .field
                margin-bottom 9px
            .field-message
                height 200px
                padding-bottom 30px
            .error-messages
                // width 280px
                @media (max-width:500px)
                    width 100%
                    margin-bottom 10px
                .error-message
                    .text
                        width initial
            .submit
                float right
                width 180px
                button
                    width 100%
                @media (max-width:500px)
                    float none
                    width 100%
        .map
            display block
            width 460px
            height 577px
            @media (max-width:959px)
                width 100%