@import 'nib'
@import '../variables'
@import '../sprite-site'

section
    &.servicos
        .triple-list
            margin-top 45px
            .title
                &:before
                    content ''
                    sprite($quality-icon)
                    display block
                    margin-bottom 10px