@import 'nib'
@import '../variables'
@import '../sprite-site'

section
    &.onde-encontrar
        .states-list
            display none
            .item
                font-size 18px
                color $color-grey-light2
                a
                    font-weight $font-lato-light
                    color $color-grey-dark2
                    &:hover
                        color $color-red
            >.items
                margin 0 -10px
                >.item
                    display none
                    &.selected
                        display block
            // .city
            //     background-color $color-red
        .states-select
            display none
            margin-top 45px
            label
                line-height 38px
                padding-left 10px
            .btn-right
                position absolute
                border-color $color-grey-dark4
                border-width 1px 1px 1px 0
                border-style solid
                top -1px
                right -1px
                width 40px
                height 40px
                background-color $color-grey-dark3
                z-index 1
            button
                &:after
                    content ''
                    position absolute
                    top 50%
                    left 50%
                    sprite($dropdown-icon)
                    margin-top (@height / -2)px
                    margin-left (@width / -2)px
        .map
            position relative
            display none
            margin 0 auto
            width 592px
            height 587px
            margin-top 45px
            background-image url(../img/onde-encontrar/background.png)
            >div
                position absolute
                left 0
                top 0
            .links
                z-index 15
            .state
                position absolute
                z-index 5
            .rs
                background-image url(../img/onde-encontrar/rs.png)
                left 252px
                top 484px
                width 108px
                height 101px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/rs-selected.png)
            .pr
                background-image url(../img/onde-encontrar/pr.png)
                left 292px
                top 416px
                width 93px
                height 62px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/pr-selected.png)
            .sp
                background-image url(../img/onde-encontrar/sp.png)
                left 314px
                top 374px
                width 126px
                height 85px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/sp-selected.png)
            .mt
                background-image url(../img/onde-encontrar/mt.png)
                left 187px
                top 186px
                width 171px
                height 166px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/mt-selected.png)
            .am
                background-image url(../img/onde-encontrar/am.png)
                left 2px
                top 44px
                width 262px
                height 185px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/am-selected.png)
            .ma
                background-image url(../img/onde-encontrar/ma.png)
                left 384px
                top 95px
                width 104px
                height 138px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/ma-selected.png)
            .pa
                background-image url(../img/onde-encontrar/pa.png)
                left 224px
                top 39px
                width 199px
                height 186px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/pa-selected.png)
            .ap
                background-image url(../img/onde-encontrar/ap.png)
                left 289px
                top 12px
                width 75px
                height 82px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/ap-selected.png)
            .rr
                background-image url(../img/onde-encontrar/rr.png)
                left 135px
                top 0px
                width 92px
                height 98px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/rr-selected.png)
            .ac
                background-image url(../img/onde-encontrar/ac.png)
                left 0px
                top 193px
                width 114px
                height 59px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/ac-selected.png)
            .ro
                background-image url(../img/onde-encontrar/ro.png)
                left 106px
                top 198px
                width 108px
                height 87px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/ro-selected.png)
            .to
                background-image url(../img/onde-encontrar/to.png)
                left 349px
                top 154px
                width 76px
                height 135px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/to-selected.png)
            .go
                background-image url(../img/onde-encontrar/go.png)
                left 311px
                top 270px
                width 109px
                height 100px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/go-selected.png)
            .df
                background-image url(../img/onde-encontrar/df.png)
                left 385px
                top 310px
                width 21px
                height 14px
                z-index 10
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/df-selected.png)
            .mg
                background-image url(../img/onde-encontrar/mg.png)
                left 343px
                top 294px
                width 165px
                height 130px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/mg-selected.png)
            .sc
                background-image url(../img/onde-encontrar/sc.png)
                left 302px
                top 468px
                width 76px
                height 52px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/sc-selected.png)
            .es
                background-image url(../img/onde-encontrar/es.png)
                left 472px
                top 351px
                width 37px
                height 53px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/es-selected.png)
            .ms
                background-image url(../img/onde-encontrar/ms.png)
                left 240px
                top 335px
                width 106px
                height 105px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/ms-selected.png)
            .ce
                background-image url(../img/onde-encontrar/ce.png)
                left 492px
                top 124px
                width 63px
                height 77px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/ce-selected.png)
            .ba
                background-image url(../img/onde-encontrar/ba.png)
                left 412px
                top 210px
                width 136px
                height 150px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/ba-selected.png)
            .pi
                background-image url(../img/onde-encontrar/pi.png)
                left 420px
                top 121px
                width 86px
                height 122px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/pi-selected.png)
            .rn
                background-image url(../img/onde-encontrar/rn.png)
                left 533px
                top 155px
                width 55px
                height 35px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/rn-selected.png)
            .pe
                background-image url(../img/onde-encontrar/pe.png)
                left 492px
                top 192px
                width 98px
                height 34px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/pe-selected.png)
            .pb
                background-image url(../img/onde-encontrar/pb.png)
                left 531px
                top 174px
                width 59px
                height 35px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/pb-selected.png)
            .al
                background-image url(../img/onde-encontrar/al.png)
                left 536px
                top 217px
                width 49px
                height 27px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/al-selected.png)
            .se
                background-image url(../img/onde-encontrar/se.png)
                left 535px
                top 226px
                width 30px
                height 33px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/se-selected.png)
            .rj
                background-image url(../img/onde-encontrar/rj.png)
                left 429px
                top 394px
                width 58px
                height 39px
                &.over,
                &.selected
                    background-image url(../img/onde-encontrar/rj-selected.png)
        &.ready
            .states-list
                display block
            @media (min-width:720px)
                .map
                    display block
                .states-list
                    margin-top -270px
                    min-height 320px
                    .city
                        &.small
                            width 240px
            @media (min-width:820px)
                .states-list
                    .city
                        &.small
                            width 290px
            @media (min-width:920px)
                .states-list
                    .city
                        &.small
                            width 340px
            @media (min-width:960px)
                .states-list
                    .city
                        &.small
                            width 370px
            @media (max-width:719px)
                .states-select
                    display block
                .states-list
                    margin-top 40px
                    min-height 0
                    .city
                        text-align center
                        width 50%
                        float left
                        padding 0 10px
            @media (max-width:600px)
                .states-list
                    .city
                        text-align center
                        width 100%