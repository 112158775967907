@import 'nib'
@import '../variables'
@import '../sprite-site'

section
    &.empresa
        .media
            margin-top 45px
            margin-bottom 50px
        .lists
            .col-1
                width 470px
                .title
                    &:before
                        sprite($quality-icon)
            .col-2
                width 460px
                .title
                    &:before
                        sprite($responsabilidade-social-icon)