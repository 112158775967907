@import 'nib'

@font-face 
  font-family 'open_sansregular'
  src url('../fonts/opensans-regular-webfont.eot')
  src url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans-regular-webfont.woff') format('woff'),
       url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
       url('../fonts/opensans-regular-webfont.svg#open_sansitalic') format('svg')
  font-weight normal
  font-style normal

@font-face 
  font-family 'open_sanssemibold'
  src url('../fonts/opensans-semibold-webfont.eot')
  src url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans-semibold-webfont.woff') format('woff'),
       url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
       url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold_italic') format('svg')
  font-weight normal
  font-style normal

@font-face 
  font-family 'open_sansbold'
  src url('../fonts/opensans-bold-webfont.eot')
  src url('../fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans-bold-webfont.woff') format('woff'),
       url('../fonts/opensans-bold-webfont.ttf') format('truetype'),
       url('../fonts/opensans-bold-webfont.svg#open_sansbold_italic') format('svg')
  font-weight normal
  font-style normal

 