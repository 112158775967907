placeholder-color(color)
        &::-webkit-input-placeholder 
            color color !important
            opacity 1 !important
        &:-moz-placeholder  /* Firefox 18- */
            color color !important
            opacity 1 !important
        &::-moz-placeholder  /* Firefox 19+ */
            color color !important
            opacity 1 !important
        &:-ms-input-placeholder  
            color color !important
            opacity 1 !important

placeholder-uppercase()
        &::-webkit-input-placeholder 
            text-transform uppercase !important
        &:-moz-placeholder  /* Firefox 18- */
            text-transform uppercase !important
        &::-moz-placeholder  /* Firefox 19+ */
            text-transform uppercase !important
        &:-ms-input-placeholder  
            text-transform uppercase !important