@import 'nib'
@import 'sprite-site'
@import 'variables'
@import 'utils'

*
    outline none

ul,
li
    list-style-type none

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
div,
ul,
li,
hr
    margin 0
    padding 0

body
    font-family $font-lato
    font-size 16px
    background-color $color-white
    color $color-grey-dark

a
    text-decoration none
    &:hover,
    &:active,
    &:visited,
    &:focus
        text-decoration none

.text-hide
    text-indent -9999px

.text-block
    color $color-grey-dark2
    
img
    border none

.btn
    border none
    border-radius 0
    text-transform uppercase
    font-size 16px
    font-weight $font-lato-black
    text-align center
    line-height 20px
    display block
    padding 10px 15px

.container
    position relative
    display block
    margin 0 auto
    padding 0
    width $container-width !important
    @media (max-width:959px)
        width 100% !important
        box-sizing border-box
        padding-left 20px
        padding-right 20px

.content
    box-sizing border-box

.items
    font-size 0
.item
    position relative

header
    background-image url(../img/header.jpg)
    background-position center top
    .container
        height 150px
        @media (max-width:500px)
            height 100px
    .toogle-menu
        position absolute
        // position fixed
        width 80px
        height 80px
        background-color $color-red
        border none
        z-index 550
        display none
        @media (max-width:959px)
            width 50px
            height 50px
        &:before,
        &:after
            content ''
            display block
            position absolute
            top 50%
            left 50%
            opacity 0
            visibility hidden
            transition opacity .3s ease
        &:before
            opacity 1
            visibility visible
            sprite($toogle-menu-open)
            margin-top (@height / -2)
            margin-left (@width / -2)
        &:after
            sprite($toogle-menu-close)
            margin-top (@height / -2)
            margin-left (@width / -2)
        &.opened
            &:before
                opacity 0
                visibility hidden
            &:after
                opacity 1
                visibility visible
    .menu-container
        position absolute
        display block
        background-color $color-white
        width 260px
        min-height 100%
        @media (max-width:959px)
            width 200px
    .menu
        // position absolute
        position fixed
        width 260px
        top 0
        left -260px
        min-height 100%
        box-sizing border-box
        z-index 530
        background-color $color-white
        border-width 0 1px 0 0
        border-color transparent
        border-style solid
        padding-bottom 200px
        display none
        &.opened
            border-color $color-grey-light9
            // border-color #000
        // &.fixed
        //     position fixed
        @media (max-width:959px)
            width 200px
            left -200px
        .items
            width 100%
            padding-top 50px
        .item
            position relative
            display inline-block
            width 260px
            margin-bottom 10px
            left -1px
            @media (max-width:959px)
                width 200px
            a
                position relative
                font-size 18px
                padding 20px
                line-height 20px
                color $color-grey-dark
                background-color $color-white
                text-decoration none
                display block
                font-weight $font-lato-light
                text-align center
                transition all .3s ease
            &:hover
                a
                    color $color-white
                    background-color $color-red
                .submenu
                    display block
                    position absolute
                    left 260px
                    top 0
                    @media (max-width:959px)
                        left 200px
        .submenu
            display none
            background-color $color-red
            padding 20px 0
            .item
                margin-bottom 0
                left 0
                &:hover
                    a
                        color $color-red
                        background-color $color-white
        .footer
            position absolute
            left 0
            bottom 0
            padding 0 30px 30px 30px
            width 100%
            .representante
                font-size 14px
                line-height 16px
                text-align center
            .aluplast-logo
                display block
                margin 0 auto
                sprite($aluplast-logo-menu)
            hr
                width 160px
                display block
                margin 30px auto
                border-color $color-grey-light
                @media (max-width:959px)
                    width 100%
            .maqina-logo
                display block
                margin 0 auto
                sprite($maqina-logo-menu)
        &.mobile
            >.items
                >.item
                    a
                        text-align left
                        padding 5px 50px 5px 20px
                        font-size 16px
                        &:hover
                            background-color $color-red !important
                            color $color-white !important
                    .submenu
                        display block
                        position relative !important
                        left 0 !important
                        top 0 !important
                        padding 0
                        background-color $color-white
                        border-color $color-red
                        border-width 0 0 1px 1px
                        border-style solid
                        margin-left -1px
                        a
                            padding-left 30px
                            font-size 14px
                            &:before
                                content ''
                                position absolute
                                top 0
                                left 0
                                width 10px
                                height 100%
                                background-color $color-red
                    &:hover
                        a
                            background-color $color-white
                            color $color-grey-dark
    .archi-logo
        position absolute
        display block
        width 272px
        height 90px
        top 0
        right 0
        background-color $color-white
        z-index 500
        &:after
            content ''
            position absolute
            top 50%
            left 50%
            sprite($archi-logo)
            margin-top (@height / -2)
            margin-left (@width / -2)
        @media (max-width:500px)
            width 217px
            height 72px
            &:after
                sprite($archi-logo-small)

.bread-crumb
    margin-top 70px
    @media (max-width:959px)
        margin-top 40px
    .item
        font-size 14px
        color $color-red
        display inline-block
        &:after
            content ''
            display inline-block
            sprite($bread-crumb-separator)
            margin 0 8px
        &.selected
            &:after
                content none
        a
            color $color-grey-dark2
            &:hover
                color $color-red
.container
    >.title
        font-size 48px
        color $color-red
        line-height 54px
        font-weight 700
        text-transform uppercase
        margin-top 5px
        &:after
            content ''
            display block
            width 160px
            height 1px
            background-color $color-grey-light3
            margin-top 20px
            margin-bottom 24px
        @media (max-width:959px)
            font-size 35px
            line-height 37px
            &:after
                margin-top 10px
                margin-bottom 15px
        @media (max-width:400px)
            font-size 25px
            line-height 27px

.media
    .row
        margin -5px
        text-align center
    .col
        float left
        margin 5px
        @media (max-width:959px)
            float none
            display inline-block
    .video-1
        position relative
        .image-proportion
            width 610px
            height 320px
            @media (max-width:959px)
                width 100%
                height auto
        @media (max-width:959px)
            width 100%
            margin 5px 0px
        .player-proportion
            iframe
                display block !important
    .image-1
        width 320px
        height 320px
    .image-2
        width 230px
        height 225px
    .image-3
        width 230px
        height 225px
    .image-4
        width 470px
        height 225px
    .image-5
        width 460px
        height 460px
        float right
    .image
        @media (max-width:959px)
            width 100%
            height auto
            float none
            img
                width 100%

.lists
    .row
        margin -5px
    .col
        position relative
        float left
        width 465px
        margin 5px
        @media (max-width:959px)
            text-align center
            width 100% !important
            margin 40px 0 0 0 !important
            &:first-child
                margin-top 0 !important
    .title
        color $color-red
        font-size 28px
        text-transform uppercase
        padding-top 45px
        &:before,
        &:after
            content ''
            display block
        &:before
            position absolute
            top 0
            left 0
        &:after
            width 160px
            height 1px
            background-color $color-grey-light3
            margin-top 16px
            margin-bottom 14px
        @media (max-width:959px)
            padding-top 0
            &:before,
            &:after
                position relative
                top initial
                left initial
                display block
                margin-left auto
                margin-right auto
    .item
        display block
        a
            font-size 16px
            line-height 24px
            vertical-align top
            display inline-block
            color $color-grey-dark2
            &:hover
                color $color-red
                text-decoration underline
            &:before
                content ''
                position relative
                display inline-block
                width 16px
                height 16px
                top 4px
                margin-right 5px
                spriteImage($link-icon)
    .col
        &.links
            .item
                a
                    &:before
                        spritePosition($link-icon)
        &.related-files
            .item
                a
                    &:before
                        spritePosition($download-icon)

.btn-red
    background-color $color-red
    color $color-white
    &:hover,
    &.selected
        background-color $color-red-dark
        color $color-white

.btn-grey
    background-color $color-grey-light2
    color $color-white
    &:hover,
    &:disabled
        background-color $color-grey-light2
        color $color-white
        opacity 1

.triple-list
    >.title
        font-size 28px
        color $color-red
        text-transform uppercase
        margin-bottom 25px
    .items
        margin -10px
    .item
        width 300px
        margin 10px
        float left
        img
            width 100%
            height auto
        @media (max-width:959px)
            width 50%
            margin 0
            padding 10px
        @media (max-width:659px)
            width 100%
    .thumb
        width 300px
        height 220px
    .content
        background-color $color-grey-light5
        border-color $color-grey-light4
        border-width 0 1px 1px 1px
        border-style solid
        padding 30px 30px 100px 30px
        &.without-image
            border-width 1px
        .title-box
            display block
        .title
            font-size 22px
            color $color-red
            text-transform uppercase
        .sub-title
            font-size 14px
            color $color-grey-dark2
            text-transform uppercase
            margin-top 2px
            font-weight $font-lato-bold
        .description
            font-size 16px
            color $color-grey-dark2
        hr
            width 100px
            border-color $color-grey-light6
            margin-left 0
            margin-right auto
            margin-top 16px
            margin-bottom 11px
        .more
            position absolute
            bottom 30px
            left 30px
            right 30px
        .btn
            width 100%
    a
        .content
            &:hover
                .btn-red
                    background-color $color-red-dark
.form
    margin-top 25px
    input,
    select,
    textarea
        font-size 16px
        height 38px
        border none
        width 100%
        box-sizing border-box
        color $color-grey-dark2
        background-color transparent !important
        font-weight $font-lato-light
        placeholder-color($color-grey-dark2)
    /html.lt-ie9 .form
        input,
        select,
        textarea
            line-height 38px
    select
        opacity 0
        position absolute
        left 0
        top 0
        width 100%
        height 40px
        z-index 5
    input,
    textarea
        padding 0 10px 0 10px
    textarea
        height 100%
        padding-top 5px
    .field
        position relative
        font-size 16px
        height 40px
        border-color $color-grey-light
        border-width 1px
        border-style solid
        box-sizing border-box
        overflow hidden
        label.placeholder
            position absolute
            display block
            top 0
            left 0
            color $color-grey-dark2
            background-color transparent !important
            font-weight $font-lato-light
            box-sizing border-box
            padding 0 10px !important
            height 38px
            line-height 38px
        &.error
            border-color $color-red-light
            input,
            select,
            textarea
                color $color-red
                placeholder-color($color-red, normal)
            .placeholder
                color $color-red
        .counter
            position absolute
            bottom 0
            left 0
            font-size 12px
            text-align right
            display block
            width 100%
            padding 0 10px
            line-height 30px
            color $color-grey-light3
            display none
        label
            font-weight $font-lato-regular
    .error-messages
        float left
        line-height 20px
        height 40px
        .error-message,
        .success-message
            position relative
            display none
            vertical-align middle
            height 40px
            font-size 0
            .text
                display inline-block
                font-size 16px
            &:after
                content ''
                position absolute
                top 50%
                left 0
        .error-message
            padding-left 34px
            .text
                color $color-red
                width 236px
            &:after
                sprite($error-icon)
                margin-top (@height / -2)px
        .success-message
            padding-left 41px
            .text
                color $color-green2
                width 229px
            &:after
                sprite($success-icon)
                margin-top (@height / -2)px
.social
    position relative
    z-index 100
    margin-top 25px
    &:after
        content ''
        display block
        width 160px
        height 1px
        background-color $color-grey-light3
        margin-top 20px
        margin-bottom 24px
    .social-button
        display inline-block
        vertical-align top
        .fb-like
            margin-left -17px
            span
                vertical-align top !important
        &.email
            margin-top -2px
            margin-left 20px
            button
                height 20px
                line-height 18px
                border-color $color-grey-light8
                border-width 1px
                border-style solid
                border-radius 3px
                background-color $color-grey-light9
                font-family $font-tahoma
                color $color-grey-dark5
                font-size 11px
                .icon
                    display inline-block
                    sprite($send-email-icon)
                    vertical-align middle
                    margin-top -5px
                    margin-right 3px
                &:hover
                    background-color $color-grey-light8
                    color $color-grey-dark
                    .icon
                        spritePosition($send-email-white-icon)
    @media (max-width:400px)
        .social-button
            display block
            margin-bottom 10px
            .fb-like
                margin-left 0
            &.email
                display block
                margin-left 0

footer
    display block
    height 30px
    background-color $color-grey-light2
    color $color-white
    line-height 30px
    font-size 13px
    text-align center
    margin-top 100px
    @media (max-width:959px)
        margin-top 70px
    @media (max-width:480px)
        margin-top 40px

@media (min-width:1025px)
    .container
        width $container-width !important
    header
        &.home
            background none
            .container
                width 100% !important
                padding 0 42px
                margin-bottom -90px
            .archi-logo
                right 42px

/*@import 'sections/home'
@import 'sections/servicos'
@import 'sections/servico-detalhe'
@import 'sections/noticias'
@import 'sections/noticia-detalhe'
@import 'sections/produtos'
@import 'sections/produto-detalhe'
@import 'sections/onde-encontrar'
@import 'sections/fale-conosco'*/