@import 'nib'
@import '../variables'

header
    &.home
        background none

section
    &.home
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        .bottom-layer
            position absolute
            content ''
            width 100%
            height 100px
            left 0
            bottom 0
            background-color rgba($color-white, .8)
            @media (max-width:959px)
                display none
        .backgrounds
            position fixed
            top 0
            left 0
            width 100%
            height 100%
            @media (max-width:959px)
                display none
            .background
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                display none
                img
                    position absolute
                    top 50%
                    left 50%
                    display block
        .container
            position relative
            height 100%
            @media (max-width:959px)
                padding 0
        .balloons-menu
            position absolute
            width 100%
            bottom 0
            left 0
            height 350px
            @media (max-width:959px)
                position relative
                margin-top 90px
            .items
                position relative
                left 0
                bottom 0
                margin 0 -10px
                height 350px
                @media (max-width:959px)
                    margin 0
            .item
                bottom 0
                width 220px
                margin 0 10px
                // height 180px
                // background-color $color-red
                box-sizing border-box
                transition background-color .3s ease
                height 347px
                .background
                    position absolute
                    width 0
                    height 0
                    top 0
                    left 0
                    opacity 0
                    display none
                .white-bottom
                    position absolute
                    bottom 0
                    left 0
                    width 100%
                    height 100px
                    display none
                    background-image url(../img/white-85-alpha.png)
                @media (max-width:959px)
                    position relative
                    // padding-top 20px
                    height 350px
                    overflow hidden
                    margin 0
                    .background
                        width 100%
                        height 100%
                        background-size cover
                        background-position center center
                    .white-bottom
                        display block
                @media (min-width:960px)
                    position absolute
                .menu
                    margin-top -95px
                    position absolute
                    bottom 0
                .picture
                    position relative
                    bottom -105px
                    left 0
                    width 220px
                    height 242px
                    z-index 10
                    img
                        position absolute
                    @media (max-width:959px)
                        left 50%
                        margin-left -110px
                .text
                    position relative
                    bottom 0
                    left 0
                    background-color $color-white
                    height 180px
                    width 100%
                    padding-top 100px
                    text-align center
                    .name
                        width 100%
                        height 75px
                        display table-cell
                        vertical-align middle
                        font-size 18px
                        color $color-grey-dark2
                        line-height 20px
                        transition color .3s ease
                        padding-left 20px
                        padding-right 20px
                        margin-left auto
                        margin-right auto
                    &:after
                        content ''
                        position absolute
                        bottom 0
                        height 6px
                        width 100%
                        left 0
                .box-content
                    position absolute
                    left 10px
                    // width 940px
                    height auto
                    bottom 350px
                    display block
                    color $color-white
                    // opacity 0
                    z-index 5
                    @media (min-width:960px)
                        opacity 0
                    &.selected
                        z-index 10
                    // @media (max-width:959px)
                    //     position relative
                    //     margin-bottom -70px
                    //     bottom 0
                    a
                        display block
                    .content
                        position absolute
                        bottom 17px
                        display block
                        // margin-bottom 17px
                        width 100%
                        height 241px
                        padding 50px 350px 50px 50px
                        box-sizing border-box
                        color $color-white
                        @media (max-width:800px)
                            height auto
                            padding 20px
                    .description1
                        font-size 20px
                    .description2
                        font-size 34px
                        font-weight $font-lato-bold
                        text-transform uppercase
                    .description3
                        font-size 16px
                    hr
                        width 160px
                        border-color rgba($color-white, .4)
                        margin-top 10px
                        margin-bottom 10px
                        text-align left
                    .more
                        position absolute
                        bottom 50px
                        right 50px
                        width 270px
                        height 50px
                        line-height 50px
                        font-size 18px
                        text-align center
                        display block
                        color $color-white
                        font-weight $font-lato-black
                        text-transform uppercase
                        letter-spacing .5px
                        border none
                        @media (max-width:800px)
                            position relative
                            bottom 0
                            right 0
                            margin-top 30px
                            margin-left auto
                            margin-right auto
                        @media (max-width:350px)
                            width 100%
                    .arrow
                        position absolute
                        bottom 0
                        width 0
                        height 0
                        border-style solid
                        border-width 17px 17px 0 17px
                        z-index 15
                &.item-1
                    @media (min-width:960px)
                        left 0
                    .background
                        @media (max-width:959px)
                            background-image url(../img/home/background-1.jpg)
                    .content
                        background-color rgba($color-red, 0.9)
                    .more
                        background-color $color-red-dark
                    .arrow
                        left 93px
                        border-color rgba($color-red, 0.9) transparent transparent transparent
                        @media (max-width:959px)
                            left 50%
                            margin-left -17px
                    .box-link
                        &:hover
                            .more
                                color $color-red-dark
                                background-color $color-white
                &.item-2
                    @media (min-width:960px)
                        left 240px
                    .background
                        @media (max-width:959px)
                            background-image url(../img/home/background-2.jpg)
                    .content
                        background-color rgba($color-blue, 0.9)
                    .more
                        background-color $color-blue-dark
                    .arrow
                        left 333px
                        border-color rgba($color-blue, 0.9) transparent transparent transparent
                        @media (max-width:959px)
                            left 50%
                            margin-left -17px
                    .box-link
                        &:hover
                            .more
                                color $color-blue-dark
                                background-color $color-white
                &.item-3
                    @media (min-width:960px)
                        right 240px
                    .background
                        @media (max-width:959px)
                            background-image url(../img/home/background-3.jpg)
                    .content
                        background-color rgba($color-orange, 0.9)
                    .more
                        background-color $color-orange-dark
                    .arrow
                        right 333px
                        border-color rgba($color-orange, 0.9) transparent transparent transparent
                        @media (max-width:959px)
                            right 50%
                            margin-right -17px
                    .box-link
                        &:hover
                            .more
                                color $color-orange-dark
                                background-color $color-white
                &.item-4
                    @media (min-width:960px)
                        right 0
                    .background
                        @media (max-width:959px)
                            background-image url(../img/home/background-4.jpg)
                    .content
                        background-color rgba($color-green, 0.9)
                    .more
                        background-color $color-green-dark
                    .arrow
                        right 93px
                        border-color rgba($color-green, 0.9) transparent transparent transparent
                        @media (max-width:959px)
                            right 50%
                            margin-right -17px
                    .box-link
                        &:hover
                            .more
                                color $color-green-dark
                                background-color $color-white
                    
                &.item-1
                    .text
                        &:after
                            background-color $color-red
                &.item-2
                    .text
                        &:after
                            background-color $color-blue
                &.item-3
                    .text
                        &:after
                            background-color $color-orange
                &.item-4
                    .text
                        &:after
                            background-color $color-green
                &.selected
                    .text
                        .name
                            color $color-white
                    &.item-1
                        .text
                            background-color $color-red
                            &:after
                                background-color $color-red-dark
                    &.item-2
                        .text
                            background-color $color-blue
                            &:after
                                background-color $color-blue-dark
                    &.item-3
                        .text
                            background-color $color-orange
                            &:after
                                background-color $color-orange-dark
                    &.item-4
                        .text
                            background-color $color-green
                            &:after
                                background-color $color-green-dark

html
    &.lt-ie9
        section
            &.home
                .bottom-layer
                    background-image url(../img/white-85-alpha.png)
                .balloons-menu
                    .item
                        .menu
                            img
                                top 45px
                        &.item-1
                            .content
                                background-color $color-red
                            .arrow
                                border-color $color-red transparent transparent transparent
                        &.item-2
                            .content
                                background-color $color-blue
                            .arrow
                                border-color $color-blue transparent transparent transparent
                        &.item-3
                            .content
                                background-color $color-orange
                            .arrow
                                border-color $color-orange transparent transparent transparent
                        &.item-4
                            .content
                                background-color $color-green
                            .arrow
                                border-color $color-green transparent transparent transparent











    // &.home1
    //     position absolute
    //     // background-image url(../img/home/background.jpg)
    //     // background-position center top
    //     top 0
    //     left 0
    //     width 100%
    //     height 100%
    //     overflow hidden
    //     // @media (max-height:768px)
    //     //     height 800px
    //     .backgrounds
    //         position absolute
    //         top 0
    //         left 0
    //         width 100%
    //         height 100%
    //         .background
    //             position fixed
    //             top 0
    //             left 0
    //             width 100%
    //             height 100%
    //             display none
    //     .container
    //         height 100%
    //     // .balloons
    //     //     position absolute
    //     //     width 100%
    //     //     left 0
    //     //     bottom 350px
    //     //     .item
    //     //     .box-content
    //     //         position absolute
    //     //         left 0
    //     //         width 100%
    //     //         bottom 50px
    //     //         display none
    //     //         color $color-white
    //     //         opacity 0
    //     //         z-index 5
    //     //         &.selected
    //     //             z-index 10
    //     //         .content
    //     //             position relative
    //     //             display block
    //     //             margin-bottom 17px
    //     //             width 100%
    //     //             padding 50px 350px 50px 50px
    //     //             box-sizing border-box
    //     //         .description1
    //     //             font-size 20px
    //     //         .description2
    //     //             font-size 34px
    //     //             font-weight $font-lato-bold
    //     //             text-transform uppercase
    //     //         .description3
    //     //             font-size 16px
    //     //         hr
    //     //             width 160px
    //     //             border-color rgba($color-white, .4)
    //     //             margin-top 10px
    //     //             margin-bottom 10px
    //     //             text-align left
    //     //         .more
    //     //             position absolute
    //     //             bottom 50px
    //     //             right 50px
    //     //             width 270px
    //     //             height 50px
    //     //             line-height 50px
    //     //             font-size 18px
    //     //             text-align center
    //     //             display block
    //     //             color $color-white
    //     //             font-weight $font-lato-black
    //     //             text-transform uppercase
    //     //             letter-spacing .5px
    //     //         .arrow
    //     //             position absolute
    //     //             bottom 0
    //     //             width 0
    //     //             height 0
    //     //             border-style solid
    //     //             border-width 17px 17px 0 17px
    //     //             z-index 15
    //     //         &.item-1
    //     //             .content
    //     //                 background-color rgba($color-red, 0.9)
    //     //             .more
    //     //                 background-color $color-red-dark
    //     //             .arrow
    //     //                 left 93px
    //     //                 border-color rgba($color-red, 0.9) transparent transparent transparent
    //     //         &.item-2
    //     //             .content
    //     //                 background-color rgba($color-blue, 0.9)
    //     //             .more
    //     //                 background-color $color-blue-dark
    //     //             .arrow
    //     //                 left 333px
    //     //                 border-color rgba($color-blue, 0.9) transparent transparent transparent
    //     //         &.item-3
    //     //             .content
    //     //                 background-color rgba($color-orange, 0.9)
    //     //             .more
    //     //                 background-color $color-orange-dark
    //     //             .arrow
    //     //                 right 333px
    //     //                 border-color rgba($color-orange, 0.9) transparent transparent transparent
    //     //         &.item-4
    //     //             .content
    //     //                 background-color rgba($color-green, 0.9)
    //     //             .more
    //     //                 background-color $color-green-dark
    //     //             .arrow
    //     //                 right 93px
    //     //                 border-color rgba($color-green, 0.9) transparent transparent transparent
    //     .balloons-menu
    //         position absolute
    //         width 100%
    //         bottom 0
    //         left 0
    //         // height 350px
    //         .items
    //             position absolute
    //             left 0
    //             bottom 0
    //             margin 0 -10px
    //         .item
    //             // position relative
    //             // display inline-block
    //             position inherit
    //             float left
    //             width 220px
    //             margin 0 10px
    //             // height 180px
                
    //             background-color $color-white
    //             box-sizing border-box
    //             transition background-color .3s ease
    //             a
    //                 position relative
    //                 display block
    //                 width 220px
    //                 height 180px
    //             &:after
    //                 content ''
    //                 position absolute
    //                 bottom 0
    //                 height 6px
    //                 width 100%
    //                 left 0
    //             &.item-1
    //                 &:after
    //                     background-color $color-red
    //             &.item-2
    //                 &:after
    //                     background-color $color-blue
    //             &.item-3
    //                 &:after
    //                     background-color $color-orange
    //             &.item-4
    //                 &:after
    //                     background-color $color-green
    //             &.selected
    //                 &.item-1
    //                     background-color $color-red
    //                     &:after
    //                         background-color $color-red-dark
    //                 &.item-2
    //                     background-color $color-blue
    //                     &:after
    //                         background-color $color-blue-dark
    //                 &.item-3
    //                     background-color $color-orange
    //                     &:after
    //                         background-color $color-orange-dark
    //                 &.item-4
    //                     background-color $color-green
    //                     &:after
    //                         background-color $color-green-dark
    //             .box-content
    //                 position absolute
    //                 left 0
    //                 width 940px
    //                 // bottom 50px
    //                 display none
    //                 color $color-white
    //                 opacity 0
    //                 z-index 5
    //                 &.selected
    //                     z-index 10
    //                 .content
    //                     position relative
    //                     display block
    //                     margin-bottom 17px
    //                     width 100%
    //                     padding 50px 350px 50px 50px
    //                     box-sizing border-box
    //                 .description1
    //                     font-size 20px
    //                 .description2
    //                     font-size 34px
    //                     font-weight $font-lato-bold
    //                     text-transform uppercase
    //                 .description3
    //                     font-size 16px
    //                 hr
    //                     width 160px
    //                     border-color rgba($color-white, .4)
    //                     margin-top 10px
    //                     margin-bottom 10px
    //                     text-align left
    //                 .more
    //                     position absolute
    //                     bottom 50px
    //                     right 50px
    //                     width 270px
    //                     height 50px
    //                     line-height 50px
    //                     font-size 18px
    //                     text-align center
    //                     display block
    //                     color $color-white
    //                     font-weight $font-lato-black
    //                     text-transform uppercase
    //                     letter-spacing .5px
    //                 .arrow
    //                     position absolute
    //                     bottom 0
    //                     width 0
    //                     height 0
    //                     border-style solid
    //                     border-width 17px 17px 0 17px
    //                     z-index 15
    //             &.item-1
    //                 .content
    //                     background-color rgba($color-red, 0.9)
    //                 .more
    //                     background-color $color-red-dark
    //                 .arrow
    //                     left 93px
    //                     border-color rgba($color-red, 0.9) transparent transparent transparent
    //             &.item-2
    //                 .content
    //                     background-color rgba($color-blue, 0.9)
    //                 .more
    //                     background-color $color-blue-dark
    //                 .arrow
    //                     left 333px
    //                     border-color rgba($color-blue, 0.9) transparent transparent transparent
    //             &.item-3
    //                 .content
    //                     background-color rgba($color-orange, 0.9)
    //                 .more
    //                     background-color $color-orange-dark
    //                 .arrow
    //                     right 333px
    //                     border-color rgba($color-orange, 0.9) transparent transparent transparent
    //             &.item-4
    //                 .content
    //                     background-color rgba($color-green, 0.9)
    //                 .more
    //                     background-color $color-green-dark
    //                 .arrow
    //                     right 93px
    //                     border-color rgba($color-green, 0.9) transparent transparent transparent
    //         .text
    //             width 220px
    //             height 75px
    //             display table-cell
    //             vertical-align middle
    //             font-size 18px
    //             color $color-grey-dark2
    //             line-height 20px
    //             text-align center
    //             transition color .3s ease
    //         .item
    //             &.selected
    //                 .text
    //                     color $color-white
    //         .picture
    //             position absolute
    //             left 0
    //             bottom 80px
    //             width 100%
    //             height 242px
    //             img
    //                 position absolute